import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/Layout';

export const IndexPageTemplate = ({ title, release, html }) => (
  <div className="flex flex-column">
    {title && <h1 className="thearcher title">{title}</h1>}
    {release && (
      <a
        className="dim link text-color"
        href={release.frontmatter.bandcampURL || release.fields.slug}
        target="_blank"
        title={release.frontmatter.releaseTitle}
      >
        <div
          className="IndexPage__cover-image"
          style={{
            backgroundImage: `url(${
              !!release.frontmatter.image.childImageSharp
                ? release.frontmatter.image.childImageSharp.fluid.src
                : release.frontmatter.image
            })`,
          }}
        />
        {html && (
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            className="subtitle tc thearcher f4"
          />
        )}
      </a>
    )}
  </div>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  release: PropTypes.object,
  html: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        release={frontmatter.selectedRelease}
        html={html}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        selectedRelease {
          fields {
            slug
          }
          frontmatter {
            releaseTitle
            bandcampURL
            embed
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      html
    }
  }
`;
